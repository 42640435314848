import React, { Component } from "react"
import { withAuthenticator } from "aws-amplify-react"
import Amplify from "aws-amplify"
import { config } from "../config"

import Layout from "../components/layout"
import SEO from "../components/seo"

import CookieService from "../services/CookieService"
import UserService from "../services/UserService"
import AppStore from "../services/AppStore"
// import UTF8 from "utf8"
import iconv from 'iconv-lite';
import { Buffer } from 'buffer';


import {SortableContainer, SortableElement, sortableHandle} from 'react-sortable-hoc'
import arrayMove from 'array-move'
import AudioPlayer from "react-h5-audio-player"

import loadingIcon from "../images/loader.svg"
import addIcon from "../images/edit_add.png"
import removeIcon from "../images/asset-delete.png"
import shuffleIcon from "../images/shuffle.png"
import playIcon from "../images/play_green.png"
import dragIcon from "../images/move.svg"
import "react-h5-audio-player/lib/styles.css"
import './music.css'

import { Scrollbars } from 'react-custom-scrollbars'

Amplify.configure(config)

class MusicPage extends Component {
  constructor(props) {
    super(props)

    this._isMounted = false
    this.state = {
      loading: true,
      playlist: [],
      selectedTrack: null,
      libData: [],
      openedArtists: []
    }
  }

  async componentDidMount() {
    this._isMounted = true
    const cookies = await CookieService.set() // eslint-disable-line no-unused-vars
    this.userInfo = await UserService.getUserInfo()

    let dataUrl = `//common.the-red-frog.com/public/music/music.json?d=${new Date().getTime()}`
    // dataUrl = `/music.json?d=${new Date().getTime()}`
    
    let libData = AppStore.get('MUSIC_LIBDATA')
    if (!libData) {
      const res = await fetch(dataUrl, { credentials: 'include'  })
      const dataObj = await res.arrayBuffer();
      const response = JSON.parse(iconv.decode(Buffer.from(dataObj), 'iso-8859-1'))
      libData = response.data
      AppStore.set('MUSIC_LIBDATA', libData)
    }

    let playlist = AppStore.get('MUSIC_PLAYLIST')

    this.player = React.createRef()

    if (this._isMounted) {
      this.setState({
        loading: false,
        libData,
        playlist: playlist ? playlist : []
      })
    }
  }

  addToPlayList(track) {
    if (this.state.playlist.filter(playsong => playsong.url === track.url).length) return
    let playlist = this.state.playlist
    playlist.push(track)
    this.setState({ playlist })
    AppStore.set('MUSIC_PLAYLIST', playlist)
  }

  addAlbumToPlayList(artist, album, trackList) {
    let playlist = this.state.playlist
    trackList.forEach(song => {
      if (this.state.playlist.filter(playsong => playsong.url === `${artist.name}/${album.name}/${song}`).length) return
      playlist.push({
        url: `${artist.name}/${album.name}/${song}`,
        name: song,
        artist: artist.name,
        album: album.name
      })
    })
    this.setState({ playlist })
    AppStore.set('MUSIC_PLAYLIST', playlist)
  }

  componentWillUnmount() {
    this._isMounted = false
  }

  toggleArtist(artist) {
    if (this.state.openedArtists.indexOf(artist.name) < 0) {
      // pnl is now closed -> open!
      this.setState({
        openedArtists: this.state.openedArtists.concat(artist.name)
      })
    } else {
      // pnl is now opened -> close
      this.setState({
        openedArtists: this.state.openedArtists.filter(artistName => artistName !== artist.name)
      })
    }
  }

  getLibraryContent() {
    let content = []
    this.state.libData.forEach((artist, artistIndex) => {
      let artistContent = []
      // if artist pnl is opened, we display album/song 
      if (this.state.openedArtists.indexOf(artist.name) > -1) {
        artist.alb.forEach((album, albumIndex) => {
          let albumContent = []
          album.songs.forEach((song, songIndex) => {
            const playlistMatch = this.state.playlist.filter(playsong => playsong.url === `${artist.name}/${album.name}/${song}`)
            albumContent.push(<button 
                  key={`key_${artistIndex}_${albumIndex}_${songIndex}`} 
                  className={playlistMatch.length ? 'song-item selected' : 'song-item'}
                  disabled={playlistMatch.length}
                  onClick={() => this.addToPlayList({ 
                    artist: artist.name,
                    album: album.name,
                    name: song,
                    url: `${artist.name}/${album.name}/${song}`
                   })}
                  >
                {song.replace('.mp3', '')}
              </button>)
          })
        artistContent.push(<div className='musicalbum-wrapper' key={`key_${artistIndex}_${albumIndex}`}>
            <div className='musicalbum-header'>
              <span>{album.name}</span>
              <button onClick={() => this.addAlbumToPlayList(artist, album, album.songs)}>
                <img src={addIcon} alt="add album to playlist"></img>
              </button>
            </div>
            <div className="song-wrapper">{albumContent}</div>
          </div>)
        })
      }
      
      content.push(<div key={`key_${artistIndex}`}>
          <button className={this.state.openedArtists.indexOf(artist.name) > -1 ? 'artist-header opened' : 'artist-header'} onClick={() => this.toggleArtist(artist)}>
            {artist.name}
          </button>
          <div className='musicalbums-wrapper'>
            {artistContent}
          </div>
        </div>)
    })
    return content
  }

  onSortEnd = ({oldIndex, newIndex}) => {
    this.setState(({playlist}) => ({
      playlist: arrayMove(playlist, oldIndex, newIndex)
    }))
  }

  renderThumb ({ style, ...props }) {
    const thumbStyle = {
      borderRadius: 6,
      backgroundColor: 'rgba(0, 164, 0, 0.4)'
    }
    return <div style={{ ...style, ...thumbStyle }} {...props} />
  }

  removeFromPlaylist(songToRemove) {
    const playlist = this.state.playlist.filter(song => song.url !== songToRemove.url)
    this.setState({ playlist })
    AppStore.set('MUSIC_PLAYLIST', playlist)
  }

  emptyPlaylist() {
    this.setState({ playlist: [], selectedTrack: null })
    AppStore.set('MUSIC_PLAYLIST', [])
  }

  shuffle(originArray) {
    var array = JSON.parse(JSON.stringify(originArray))
    var currentIndex = array.length, temporaryValue, randomIndex;
  
    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
  
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;
  
      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }
  
    return array;
  }

  shufflePlaylist() {
    if (!this.state.playlist.length) return
    const playlist = this.shuffle(this.state.playlist)
    this.setState({ playlist, selectedTrack: playlist[0] })
    AppStore.set('MUSIC_PLAYLIST', playlist)
  }

  getPlayListContent(selectedTrack) {
    if (!this.state.playlist.length) {
      return <div style={{ padding: '5rem 1rem', textAlign: 'center', color: '#999', height: 'calc(100vh - 140px)' }}>Click on a song to add to the playlist</div>
    }

   const DragHandle = sortableHandle(() => <div className='playlist-draghandle'>
        <img src={dragIcon} alt='drag'></img>
      </div>)

   const SortableItem = SortableElement(({ value }, index) => {
    return <div key={`playlist_${index}`} className={selectedTrack === value ? 'playlist-item playing' : 'playlist-item'}>
      <div className='playlist-artist'>{value.artist}</div>
      <div className='playlist-song'>{value.name.replace('.mp3', '')}</div>
      <DragHandle />
    <button className='play-action' onClick={() => this.setState({ selectedTrack: value })}>
      <img src={playIcon} alt='play'></img>
    </button>
    <button className='remove-action' onClick={() => this.removeFromPlaylist(value)}>
      <img src={removeIcon} alt='remove from playlist'></img>
    </button>
    </div>}
  )

  const SortableList = SortableContainer(({ tracks }) => {
    return (
      <div>
        {this.state.playlist.map((item, index) => (
          <SortableItem key={`item-${index}`} index={index} value={item} useDragHandle={true} />
        ))}
      </div>
    );
  })

  return <Scrollbars 
        style={{ width: '100%', height: 'calc(100vh - 140px)' }}
        renderThumbVertical={this.renderThumb}
        >
      <SortableList items={this.state.itemList} onSortEnd={this.onSortEnd} axis='y' helperClass='sortableHelper' />
    </Scrollbars>
  }

  playNext() {
    let index = 0
    if (this.state.selectedTrack) index = this.state.playlist.findIndex(song => song.url === this.state.selectedTrack.url)
    if (index > this.state.playlist.length - 2) return
    this.setState({ selectedTrack: this.state.playlist[index+1] })
  }

  playPrevious() {
    let index = 0
    if (this.state.selectedTrack) index = this.state.playlist.findIndex(song => song.url === this.state.selectedTrack.url)
    if (index < 0) return
    this.setState({ selectedTrack: this.state.playlist[index-1] })
  }

  render() {
    let content = ''
    if (this.loading) {
      content = <div className="loader" style={{ margin: '10rem' }}>
          <img src={loadingIcon} alt="loading" />
        </div>
    } else {
      let autoPlay = false
      let selectedTrack = null

      if (this.state.playlist.length) {
        if (this.state.selectedTrack && !this.state.playlist.filter(song => song.url === this.state.selectedTrack.url).length) {
          this.setState({ selectedTrack: null })
        }
        if (!this.state.selectedTrack) {
          selectedTrack = this.state.playlist[0]
        } else {
          selectedTrack = this.state.selectedTrack
          autoPlay = true
        }
      }

      let url = ''
      if (selectedTrack) url = `//common.the-red-frog.com/public/music/${encodeURI(selectedTrack.url)}`

      content = <>
          <div key='player' className='music-player no-select'>
            <AudioPlayer
              ref={this.player}
              src={url}
              onEnded={e => this.playNext()}
              onClickPrevious={e => this.playPrevious()}
              onClickNext={e => this.playNext()}
              autoPlay={autoPlay}
              showSkipControls={true}
              showJumpControls={false}
            />
            <div key='play-content' className='playlist-content'>
              <div key='play-content-header' className='playlist-content-header'>
                <span>Playlist</span>
                <button onClick={() => this.emptyPlaylist()}>
                  <img src={removeIcon} alt='clear playlist'></img>
                </button>
                <button onClick={() => this.shufflePlaylist()}>
                  <img src={shuffleIcon} alt='shuffle playlist'></img>
                </button>
              </div>
              <div key='play-content-list' className='playlist-content-list'>{this.getPlayListContent(selectedTrack)}</div>
            </div>
          </div>
          <div key='lib-content' className='music-lib-content'>{this.getLibraryContent()}</div>  
        </>
    }

    return (
      <Layout menu="MUSIC">
        <SEO title="Musique" />
        <div className="music">
          <h1>Musique</h1>
          {content}
        </div>
      </Layout>
    )
  }
}

export default withAuthenticator(MusicPage, {
  includeGreetings: false,
})
